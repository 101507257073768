/* nunito-sans-regular - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('nunito-sans-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('nunito-sans-v6-latin-regular.woff') format('woff'); /* Modern Browsers */
}

/* nunito-sans-600 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('nunito-sans-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('nunito-sans-v6-latin-600.woff') format('woff'); /* Modern Browsers */
}