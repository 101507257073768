html {
  box-sizing: border-box;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  white-space: pre-line;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: 0;
	border-radius: 10px;
	background-color: #E4EAF0;
}

*::-webkit-scrollbar
{
	width: 5px;
  height: 5px;
	background-color: #E4EAF0;
  &:hover{
    width: 8px;
  }
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: 0;
	background-color: #7587A3;
}

//disabel input number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.App {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  max-width: 100vw;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}

.smallPadding {
  padding-left: 3px !important;
  padding-right: 0 !important;
  display: block;
  flex: 1;
}

.dateTextField {
  display: flex !important;
  padding: 0 !important;

  input {
    padding-left: 10px !important;
  }

  .MuiInputAdornment-root {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
    
  }

  button {
    padding: 0;
    padding-right: 0.15rem;
    margin: 0;

    svg {
      font-size: 16px;
    }
  }
}

.MuiPaper-rounded:has(.MuiCalendarPicker-root)
{
  border-radius: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', 'OpenSanc', sans-serif;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 17px;
  color: #333333;
  background: white;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
}

ol, ul {
  list-style: none;
}

p, ul, li {
  margin: 0;
  padding: 0;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: 0;
    // box-shadow: none; // we need box shadow
  }
}

.MuiSelect-icon{
  font-size: 24px;
}
a {
  color: #00B5E2;
}

.formUnder {
  display: inline-block;
  width: auto !important;
  min-width: auto;
}

.underInput {
  color: blue;
  height: auto;
  padding: 0;
  margin: 0;
  width: 100% !important;
  min-width: auto;
  border: none;
  font-size: inherit;
  box-shadow: none;
  border-bottom: 3px solid #ededed;
  border-radius: 3px;
}

.bold, .bold > * {
  font-weight: bold;
}

.minCell > * {
  min-width: 130px !important;
}

.a {
  cursor: pointer;
  text-decoration: underline;
}

.a:hover {
  text-decoration: underline;
}


/* evorra styles */
.color-tech-grey {
  color: #7587A3
}

.bg-tech-grey {
  background-color: #7587A3
}

.debug {
  outline: 1px solid red;
}

.cursor {
  cursor: pointer
}

.iconCircle {
  border: 1px solid transparent;
  aspect-ratio: 1/1;
  padding: 0 0.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(222, 222, 222, 0.85);
  background-color: white;
}

.shadow-box{
  box-shadow: rgba(196, 211, 241, 0.6) 0px 0px 6px;
  border-radius: 10px;
}

button {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition: all 0.25s;
  border: none;

  &:hover {
    transform: scale(103%);
  }

  &:disabled {
    cursor: initial;
    opacity: 0.3;
  }

  [iconButton] {
    aspect-ratio: 1/1
  }
}

[data-icon-button-zone] {
  button {
    aspect-ratio: 1/1
  }
}

[data-active=true] > *{
  border-bottom:1px solid #00B5E2;
  border-top:1px solid #00B5E2;
  &:first-of-type{
    border-left:1px solid #00B5E2;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  &:last-of-type{
    border-right:1px solid #00B5E2;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}
.shadowBox1{
  box-shadow: 0px 0px 3px rgba(196, 211, 241, 0.65);
}
.shadowBox2{
  box-shadow: 0px 0px 6px rgba(196, 211, 241, 0.85);
}

table.dspTable {
  td{height:48px;
    border-bottom: 1px solid #E4EAF0;}
  thead {
    td {
      border-top: 1px solid #E4EAF0;
      color: #7587A3
    }
  }
}

table.accountAssociations {
  thead {
    tr{
      border-bottom: 1px solid #E4EAF0;
    }
    td {
      // border-top: 1px solid #E4EAF0;
      color: #7587A3;
      padding: 0.25rem 0;
      min-height:48px;
    }
  }
  tbody{
    tr{
      border-bottom: 1px solid #E4EAF0;
    }
    td{
      height:40px;
      padding: 0.25rem 0.1rem;
      }
  }
}

.interests-tree{
  .MuiTreeItem-iconContainer{
    width: 30px;
  }
  li ul{
    &:before{
      content:'Filter for:';
      color:#777;
      font-weight: bold;
    }
  }
}
.MuiDataGrid-virtualScroller{
  padding-bottom: 15px;
}
.titleCell{
  color:'red'
}
table.channelSummary{
  width: 100%;
  border-collapse: collapse;
  thead{
    tr{
      border-bottom: 1px solid #E4EAF0;
      th, th p{
        color: #7587A3;
        padding: 0.25rem 0;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
  tbody{
    tr{
      border-bottom: 1px solid #E4EAF0;
      &:last-child{
        border-bottom: 0;
      }
      td{
        padding-block: 8px;
      }
      &.total{
        td, td p{
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }
}

.hoverPointer:hover{
  cursor:  pointer !important;
}
.hoverPointer div input:hover{
  cursor:  pointer !important;
}



.react-flow__attribution{ display: none;}
//.react-flow__node{cursor:default}

//cockpit hide attribution
#application-footer .attribution-container{
  display: none;
}

.previewAudiencePopup .MuiPaper-root{
  max-width: 900px;
  border:0 !important
}
.previewAudiencePopup .MuiDialogContent-root{
  max-width: 900px;
}

.audience-builder-sum-preview{
  justify-content: center;
}
.audience-builder-sum-preview h4{
  font-size: 16px;
  line-height: 17px;
}
.reactour__helper{
  padding-top: 45px !important;
  nav[data-tour-elem="navigation"]{
    position: absolute;
    top: 19px;
  }
}